import { H2 } from "../../../../ui"
import { Container, Answer, Faq } from "./styled"
import { FaqType } from "../../../shared/types"

export const Faqs = ({ faqs }: { faqs: FaqType[] }) => {
  return (
    <Container>
      {faqs.map((faq: FaqType) => (
        <Faq id={faq.node.uid} key={faq.node.uid}>
          <H2>{faq.node.data.question.text}</H2>
          <Answer
            dangerouslySetInnerHTML={{
              __html: faq.node.data.answer.html
                .replaceAll("&lt;", "<")
                .replaceAll("&gt;", ">"),
            }}
          />
        </Faq>
      ))}
    </Container>
  )
}
