import { CategoryType } from "../../../shared/types"
import { Container, Title } from "./styled"
import { Subheading } from "../../../shared/styled"
import { GeneratedGatsbyImage } from "../../../../utils/utils"

export const Heading = ({ categoryInfo }: { categoryInfo: CategoryType }) => {
  return (
    <Container>
      <GeneratedGatsbyImage
        imageData={categoryInfo.category_image}
        alt={categoryInfo.title.text}
      />
      <Title>{categoryInfo.title.text}</Title>
      <Subheading
        dangerouslySetInnerHTML={{ __html: categoryInfo.subheading.html }}
      />
    </Container>
  )
}
