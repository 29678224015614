import SEO from "../../SEO"
import { schemaMarkup } from "./seoSchema"
import { Navigation } from "../../ui/Navigation"
import { FaqCategoryPageBody, CategoryLinksContainer } from "./styled"
import { Heading, Faqs, BackToTop, BackButton } from "./sections"
import { FaqTopics, FaqCategoryLinks } from "../shared"
import { useWindowSize } from "../../hooks"
import { FaqCategoryPageProps } from "./types"

export const FaqCategoryBody = ({
  data,
}: {
  data: FaqCategoryPageProps
}): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <FaqCategoryPageBody>
      {isMobile && <BackButton />}
      {data.categoryInfo && <Heading categoryInfo={data.categoryInfo} />}
      {data.faqs.length > 0 && (
        <>
          <FaqTopics title={`${data.faqs.length} topics`} topics={data.faqs} />
          <Faqs faqs={data.faqs} />
        </>
      )}
      {data.categoriesInfo && (
        <CategoryLinksContainer>
          <FaqCategoryLinks
            categoriesInfo={data.categoriesInfo}
            faqs={data.allFaqs}
          />
        </CategoryLinksContainer>
      )}
      {data.popularTopics.length > 0 && (
        <FaqTopics title={"Popular Topics"} topics={data.popularTopics} />
      )}
      <BackToTop />
    </FaqCategoryPageBody>
  )
}

export const FaqCategoryPage = ({
  data,
}: {
  data: FaqCategoryPageProps
}): JSX.Element => {
  return (
    <>
      <SEO
        title={`${data.categoryInfo.title.text} | FAQs | Cerebral`}
        description="All the info on Cerebral, all in one place."
        schemaMarkup={schemaMarkup(data.faqs)}
      />
      <Navigation />
      <FaqCategoryBody data={data} />
    </>
  )
}
