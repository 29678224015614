import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import { FaqCategoryPage } from "../../components/FaqPages/FaqCategoryPage"
import {
  CategoryType,
  FaqCategoriesInfoType,
  FaqType,
} from "../../components/FaqPages/shared/types"
interface FaqCategoriesProps {
  pageContext: {
    category: string
    categoryInfo: CategoryType
    categoriesInfo: FaqCategoriesInfoType
    popularTopics: FaqType[]
    allFaqs: FaqType[]
  }
  data: {
    categoryFaqs: { edges: FaqType[] }
  }
}

const FaqCategories = (props: FaqCategoriesProps) => {
  const pageContext = props.pageContext
  const data = {
    categoryInfo: pageContext.categoryInfo,
    categoriesInfo: pageContext.categoriesInfo,
    faqs: props.data.categoryFaqs.edges,
    allFaqs: pageContext.allFaqs,
    popularTopics: pageContext.popularTopics,
  }
  return (
    <Layout>
      <FaqCategoryPage data={data} />
    </Layout>
  )
}

export const query = graphql`
  query ($category: String!) {
    categoryFaqs: allPrismicFaqRedesign(
      filter: {
        data: { categories: { elemMatch: { category: { eq: $category } } } }
      }
    ) {
      edges {
        node {
          uid
          data {
            categories {
              category
            }
            answer {
              html
            }
            question {
              text
            }
          }
        }
      }
    }
  }
`

export default FaqCategories
