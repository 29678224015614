import { FaqType } from "../shared/types"

export const schemaMarkup = (faqs: FaqType[]) => {
  const seoFaqs = faqs.map(faq => ({
    "@type": "Question",
    name: faq.node.data.question.text,
    acceptedAnswer: {
      "@type": "Answer",
      text: faq.node.data.answer.html,
    },
  }))
  return {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: seoFaqs,
  }
}
